<template>
  <div class="storeBox">
    <div class="card-box">
      <div class="card-hd">
        <div class="card-hd-left">
          运营费用表列表【还没对接】
        </div>
        <div class="card-hd-right">
        </div>
      </div>
    </div>

    <div class="handleLineBox">
      <!-- select -->
      <div class="baseSelect">
        <!-- 模糊搜索 -->
        <SearchBox
          :searchVal="baseData.searchVal"
          placeholder="请输入运营费用表名称"
          @searchCall="searchHandle"
        ></SearchBox>
        <!-- 下拉选择 -->
        <SelectDown
          :selectVal="baseData.selectVal"
          :selectData="baseData.selectData"
          @selectDownCall="selectDownHandle"
        ></SelectDown>
        <el-button type="primary" icon="CirclePlus" @click="SimpleDialogHandle('sku')">新增运营费用表</el-button>
        <!-- 弹窗 Sku层 -->
        <div class="detailFormDialogBox">
          <el-dialog
              v-model="baseData.SimpleDialogShow"
              width="1140px"
              align-center
              :close-on-click-modal="false"
              @close="baseData.SimpleDialogShow = false"
          >
            <div class="card-box">
              <div class="card-hd">
                <div class="card-hd-left">
                  新增运营费用表
                </div>
                <div class="card-hd-right">
                </div>
              </div>
              <div class="card-bd">
                <div class="goodsForm">
                  <el-tabs tab-position="left"
                    v-model="tabsSku.currentIndex"
                    @tab-click="tabsListHandle">
                    <el-tab-pane
                      v-for="item in tabsSku.list"
                      :key="item.name"
                      :label="item.title"
                      :name="item.name">
                      <template #label>
                        {{ item.title }}
                      </template>
                    </el-tab-pane>
                  </el-tabs>
                  <div class="goodsFormBox">
                    <el-scrollbar
                      @scroll="doScroll"
                      ref="goodsFormScoll"
                      :always="true">
                      <el-form
                        ref="ruleFormOperateRef"
                        :model="ruleFormOperate"
                        status-icon
                        :rules="rulesSkuCost"
                      >
                        <div class="card-box" id="cardItem0">
                          <div class="card-hd">
                            <div class="card-hd-left">
                              基础信息
                            </div>
                            <div class="card-hd-right">
                            </div>
                          </div>
                          <div class="card-bd">
                            <div class="card-bd-item is-alone">
                              <el-form-item label="运营费用表名称" prop="templateName">
                                <el-input v-model="ruleFormOperate.templateName"
                                  :min="0"
                                  maxlength="30"
                                  placeholder="运营费用表名称"
                                  show-word-limit
                                  clearable
                                >
                                </el-input>
                              </el-form-item>
                            </div>
                          </div>
                        </div>
                        <div class="card-box" id="cardItem1">
                          <div class="card-hd">
                            <div class="card-hd-left">
                              对账中心表
                            </div>
                            <div class="card-hd-right"></div>
                          </div>
                          <div class="card-bd">
                            <div class="card-hd-right">
                              <el-button
                                type="primary"
                                icon="Upload"
                                @click="comHandleHandleUpload('duizhang')"
                              >上传对账中心表</el-button>
                              <el-button
                                type="primary"
                                icon="Upload"
                                @click="getWebviewDataHandle('duizhang')"
                              >获取对账中心表</el-button>
                            </div>
                          </div>
                          <div class="card-bd">
                            <div class="height-auto-table">
                              <el-table :data="ruleFormOperate.list.duizhang" stripe style="width: 960px">
                                <!-- <el-table-column prop="id" label="skuId" width="120" /> -->
                                <el-table-column prop="日期" label="日期" width="120" />
                                <el-table-column prop="进宝佣金" label="进宝佣金" />
                                <!-- <el-table-column prop="进宝费率（计算后得知" label="进宝费率（计算后得知" /> -->
                                <el-table-column prop="其它预估服务" label="其它预估服务" />
                                <el-table-column prop="技术服务费" label="技术服务费" />
                                <el-table-column prop="扣款" label="扣款" />
                                <el-table-column prop="优惠券" label="优惠券" />
                                <el-table-column prop="好评有礼金" label="好评有礼金" />
                                <el-table-column prop="其他支出" label="其他支出" />
                              </el-table>
                            </div>
                          </div>
                        </div>
                        <div class="card-box" id="cardItem2">
                          <div class="card-hd">
                            <div class="card-hd-left">
                              全站推广表
                            </div>
                            <div class="card-hd-right"></div>
                          </div>
                          <div class="card-bd">
                            <div class="card-hd-right">
                              <el-button
                                type="primary"
                                icon="Upload"
                                @click="comHandleHandleUpload('tuiguang')"
                              >上传全站推广表</el-button>
                              <el-button
                                type="primary"
                                icon="Upload"
                                @click="getWebviewDataHandle('tuiguang')"
                              >获取全站推广表</el-button>
                            </div>
                          </div>
                          <div class="card-bd">
                            <div class="height-auto-table">
                              <el-table :data="ruleFormOperate.list.tuiguang" stripe style="width: 960px">
                                <!-- <el-table-column prop="id" label="skuId" width="120" /> -->
                                <el-table-column prop="日期" label="日期"  width="120" />
                                <el-table-column prop="推广费" label="推广费"/>
                              </el-table>
                            </div>
                          </div>
                        </div>
                      </el-form>
                    </el-scrollbar>
                  </div>
                </div>
              </div>
            </div>
            <template #footer>
              <span class="dialog-footer">
                <el-button type="primary" @click="submitFormSkuCost(ruleFormOperateRef)">保存</el-button>
              </span>
            </template>
          </el-dialog>
        </div>
      </div>
    </div>

    <!-- table -->
    <div class="baseTable">
      <!-- <el-scrollbar> -->
      <el-table
        v-loading="baseData.tableLoading"
        :data="baseData.tableListData"
        style="width: 100%;">
        <el-table-column
          v-for="(item, index) in baseData.tableHdData"
          :key="index"
          :prop="item.prop"
          :label="item.label"
          :min-width="item?.minWidth"
          :fixed="item.prop == 'handle' ? 'right' : item.prop == 'name' ? 'left' : false"
          align="center"
        >
          <template #default="scope">
            <el-tag
              v-if="item.prop == 'platform'"
              type="danger"
              disable-transitions
            >
              {{ scope.row.platform }}
            </el-tag>
            <div v-else-if="item.prop == 'handle'">
              <el-button size="small" type="primary" @click="editSkuCostHandle(scope.row.id)">编辑</el-button>
              <el-button size="small" type="danger" @click="delSkuCostHandle(scope.row.id)">删除</el-button>
            </div>
            <div v-else-if="item.prop == 'address'">
              {{ scope?.row[item.prop] ? scope.row[item.prop] : '未设置' }}
            </div>
            <span v-else>
              {{ scope.row[item.prop] }}
            </span>
          </template>
        </el-table-column>
        <!-- table empty -->
        <template #empty>
          <div class="baseTableEmpty">
            <el-empty description="暂无运营费用表">
              <el-button type="primary" icon="CirclePlus" @click="SimpleDialogHandle('sku')">新增运营费用表</el-button>
            </el-empty>
          </div>
        </template>
      </el-table>
      <!-- </el-scrollbar> -->
    </div>

    <!-- pagination 分页 -->
    <div class="basePagination">
      <el-config-provider :locale="zhCn">
        <el-pagination
          v-model:current-page="baseData.currentPage"
          v-model:page-size="baseData.pageSize"
          :page-sizes="baseData.pageSizes"
          layout="total, sizes, prev, pager, next, jumper"
          :total="baseData.pageTotal"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </el-config-provider>
    </div>

    <UploadExcel
      :onSuccess="excelCall"
      ref="UploadExcelCom"
      v-show="exlcelData?.header.length === 0"
    ></UploadExcel>

  </div>
</template>

<script setup>
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
// eslint-disable-next-line
import UploadExcel from '@/components/UploadExcel/index.vue'
import SelectDown from '@/components/SelectDown/index.vue'
import SearchBox from '@/components/SearchBox/index.vue'
import { onMounted, reactive, watch, ref, nextTick } from 'vue'
import { skuCostList, skuCostPageList, skuCostAdd, skuCostDel } from '@/api/report.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import { timeToDate, jsonToNewName } from '@/utils/unitTransform.js'

const UploadExcelCom = ref(null)
const exlcelData = reactive({
  header: [],
  results: []
})
const baseData = reactive({
  selectTimes: '',
  selectVal: 'Option2',
  selectData: [
    {
      value: 'Option1',
      label: 'Option1'
    },
    {
      value: 'Option2',
      label: 'Option2'
    }
  ],
  tableLoading: false,
  tableHdData: [
    { label: '运营费用表名称', prop: 'name', minWidth: '200' },
    { label: '创建时间', prop: 'created_at', minWidth: '180' },
    { label: '操作', prop: 'handle', minWidth: '250' }
  ],
  tableListData: [],
  currentPage: 1,
  pageSize: 100,
  pageTotal: 0,
  pageSizes: [100, 200, 300, 400],
  SimpleDialogShow: false,
  searchVal: ''
})
// 基础组件 事务 start
const goodsFormScoll = ref()
// tabs信息
// tabs sku信息
const tabsSku = reactive({
  currentIndex: 0,
  list: [
    { title: '基础信息', name: 0 },
    { title: '对账中心表', name: 1 },
    { title: '全站推广表', name: 2 }
  ]
})
// elementPlus滚动条缓动
const scrollToTopTransition = (willGo, nowSite = 0, duration = 300) => {
  let passedTime = 0
  const originTime = Date.now()
  // 贝塞尔缓动式由0到1
  const easeingFunction = (t) => {
    if (t < 0.5) {
      return (4 * t * t * t)
    } else {
      return ((t - 1) * (2 * t - 2) * (2 * t - 2) + 1)
    }
  }
  const _scrollToTop = () => {
    if (passedTime < duration) {
      passedTime = Date.now() - originTime
      // duration(默认300毫秒)内重复执行
      requestAnimationFrame(_scrollToTop)
      // 当前位置nowSite到预期位置willGo的真实滚动距离
      const trueDistance = (nowSite + (willGo - nowSite) * (easeingFunction(passedTime / duration)))
      goodsFormScoll.value.setScrollTop(trueDistance)
    }
  }
  _scrollToTop()
}
// tabs点击事件
const tabsListHandle = (e) => {
  const indexNum = Number(e.index)
  const willGoCard = document.querySelector(`#cardItem${indexNum}`)
  willGoCard.classList.add('willFocus')
  setTimeout(() => {
    willGoCard.classList.remove('willFocus')
  }, 2000)
  scrollToTopTransition(tabsSku.list[indexNum].offsetTop - 12, baseData.scrollSite)
}
// tabs 监听滚动
const doScroll = (event) => {
  const list = tabsSku.list
  baseData.scrollSite = event.scrollTop
  for (const i in list) {
    const { offsetTop, name } = tabsSku.list[i]
    if (offsetTop >= baseData.scrollSite) {
      tabsSku.currentIndex = name
      break
    }
  }
}
// 获取cradItem锚点距顶坐标
const getCardItemSite = () => {
  nextTick(() => {
    const list = tabsSku.list
    for (const i in list) {
      tabsSku.list[i].offsetTop = document.querySelector(`#cardItem${i}`).offsetTop
    }
  })
}

// 搜索事件
const searchHandle = (type, searchBox) => {
  baseData.searchVal = type ? searchBox.searchVal : ''
  getSkuCostPageList()
}

// tabs currentIndex监听
watch(() => tabsSku.currentIndex, (newValue) => {
  console.log('watch 已触发', newValue)
})
watch(() => baseData.SimpleDialogShow, (newValue) => {
  newValue && getCardItemSite()
})

// 运营费用表表单 action
// 表单 信息
const ruleFormOperate = reactive({
  templateName: '', // 模板名称
  list: []
})
// 表单 验证相关
const ruleFormOperateRef = ref()
// 表单 模板名校验规则
const checkSkuCostName = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('请输入运营费用表名称'))
  } else {
    callback()
  }
}
// 表单 校验规则
const rulesSkuCost = reactive({
  templateName: [{ required: true, validator: checkSkuCostName, trigger: 'blur' }]
})
// 表单 验证后提交表单
const submitFormSkuCost = (formEl) => {
  if (!formEl) return
  formEl.validate((valid) => {
    if (valid) {
      console.log('提交!', ruleFormOperate)
      addOperatefeeHandle(ruleFormOperate)
    } else {
      console.log('不能提交')
      return false
    }
  })
}
// 运营费用表表单 end
// 基础组件 事务 end

// 分页相关
const handleSizeChange = (val) => {
  console.log(`${val} items per page`)
}
const handleCurrentChange = (val) => {
  console.log(`current page: ${val}`)
}
// 下拉选择相关
const selectDownHandle = (val) => {
  baseData.selectVal = val
}
// simpleDialog 弹窗唤醒事件
const SimpleDialogHandle = (type) => {
  baseData.SimpleDialogShow = true
}
// 获取新开页面数据事件
const getWebviewDataHandle = (type) => {
  console.log('获取新开页面数据事件,类型为：', type)
  localStorage.setItem('otherWinType', type)
  window.parent.postMessage({ getWebviewData: type }, '*')
}

// 获取SKU成本列表
const getSkuCostList = async () => {
  const res = await skuCostList({
    template_name: baseData.searchVal
  })
  console.log('getSkuCostList', res)
}
// 获取SKU成本分页列表
const getSkuCostPageList = async () => {
  const resData = await skuCostPageList({
    template_name: baseData.searchVal,
    page: baseData.currentPage,
    page_size: baseData.pageSize
  })
  for (const i in resData.data) {
    const dataItem = resData.data[i]
    if (dataItem?.created_at) {
      dataItem.created_at = timeToDate(dataItem?.created_at)
    }
  }
  baseData.tableListData = resData.data
  baseData.pageTotal = resData.total
  console.log('getSkuCostPageList', resData)
}
// 新增运营费用表接口
const addOperatefeeHandle = async (ruleForm) => {
  console.log('ruleFormruleFormruleForm', ruleForm, ruleFormOperate.list)
  if (ruleFormOperate?.list?.duizhang) {
    for (const i in ruleFormOperate.list.duizhang) {
      const duizhangItem = ruleFormOperate.list.duizhang[i]
      jsonToNewName(duizhangItem, '日期', 'date')
      jsonToNewName(duizhangItem, '进宝佣金', 'jinbao_yongjin')
      jsonToNewName(duizhangItem, '其它预估服务', 'other_fuwu')
      jsonToNewName(duizhangItem, '技术服务费', 'jishufuwu_fee')
      jsonToNewName(duizhangItem, '扣款', 'koukuan')
      jsonToNewName(duizhangItem, '优惠券', 'youhuiquan')
      jsonToNewName(duizhangItem, '好评有礼金', 'haoping_lijin')
      jsonToNewName(duizhangItem, '其他支出', 'other_zhichu')
      console.log('duizhangItem  duizhangItem', duizhangItem)
    }
  }
  if (ruleFormOperate?.list?.tuiguang) {
    for (const i in ruleFormOperate.list.tuiguang) {
      const tuiguangItem = ruleFormOperate.list.tuiguang[i]
      jsonToNewName(tuiguangItem, '日期', 'date')
      jsonToNewName(tuiguangItem, '推广费', 'tuiguang_fee')
    }
  }
  console.log('Comedy 添加运营费用模板')
  if (ruleForm !== 10086) return
  await skuCostAdd({
    template_name: ruleForm.templateName,
    content: JSON.stringify(ruleFormOperate.list)
  })
  getSkuCostPageList()
  baseData.SimpleDialogShow = false
}
// 编辑模板接口
const editSkuCostHandle = (id) => {
  console.log('editTemplateHandle 编辑模板接口', id)
}
// 删除模板事件 — 询问
const delSkuCostHandle = (id) => {
  ElMessageBox.confirm(
    '删除后该运营费用表将不可再次使用，是否继续？',
    {
      confirmButtonText: '删除',
      cancelButtonText: '取消',
      type: 'warning'
    }
  ).then(() => {
    delApiHandle(id)
  }).catch(() => {
  })
}
// 删除模板事件 — 接口
const delApiHandle = async (id) => {
  await skuCostDel({
    ids: [id]
  })
  getSkuCostPageList()
}

onMounted(() => {
  getSkuCostList()
  getSkuCostPageList()
})

// 点击调用子组件的上传事件
const comHandleHandleUpload = (scene) => {
  UploadExcelCom.value && UploadExcelCom.value.handleUpload('', scene)
}
// excel组件回调
const excelCall = (resData) => {
  console.log('excel组件回调', resData, '场景为', resData.scene)
  const callDuizhangRule = ['日期', '进宝佣金', '其它预估服务', '技术服务费', '扣款', '优惠券', '好评有礼金', '其他支出']
  const callTuiguangRule = ['日期', '推广费']
  if (resData.scene) {
    switch (resData.scene) {
      case 'duizhang':
        if (resData.header.length !== 8) {
          ElMessage.error('对账中心表头必须为"日期,进宝佣金,技术服务费,扣款,优惠券,好评有礼金,其它预估服务,其他支出"')
        } else {
          for (const i in resData.header) {
            if (callDuizhangRule.indexOf(resData.header[i]) === -1) {
              ElMessage.error('对账中心表头必须为"日期,进宝佣金,技术服务费,扣款,优惠券,好评有礼金,其它预估服务,其他支出"')
              return
            }
          }
          ruleFormOperate.list.duizhang = resData.results
          ElMessage.success('对账中心表上传成功"')
        }
        break
      case 'tuiguang':
        if (resData.header.length !== 2) {
          ElMessage.error('商品推广表表头必须为日期，推广费')
        } else {
          for (const i in resData.header) {
            if (callTuiguangRule.indexOf(resData.header[i]) === -1) {
              ElMessage.error('商品推广表表头必须为日期，推广费')
              return
            }
          }
          ruleFormOperate.list.tuiguang = resData.results
          ElMessage.success('商品推广表上传成功"')
        }
        break
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/variables.scss';
@import '~@/styles/mixin.scss';
@import '~@/styles/report/index.scss';
.upload-excel {
  opacity: 0 !important;
  height: 0 !important
}
</style>
